import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import TableAccountId from '../../markdowns/sections/docs/accounts-and-branches/response-account-id.md';
import TableListBranches from '../../markdowns/sections/docs/accounts-and-branches/response-list-branches.md';
import TableRetrieveBranchDetails from '../../markdowns/sections/docs/accounts-and-branches/response-retrieve-branch-details.md';
import Message from '../../enb-styled/Message.js';
export const _frontmatter = {
  "title": "Accounts and Branches"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Accounts and Branches`}</h1>
    <div id="account-id">
      <h2>{`Account ID`}</h2>
      <p>{`This action returns the account ID(s) that are available for the logged in authenticated user.`}</p>
      <div className="m-5">
    <img src="/images/docs/accounts-and-branches/get-account-id.png" alt="Get Account ID" />
      </div>
      <div className="markdown-section">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}></th>
              <th parentName="tr" {...{
                "align": null
              }}></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Method`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/development/RetrieveCurrentAccount/"
                }}>{`GET: Accounts`}</a></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Response`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><TableAccountId mdxType="TableAccountId" /></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div id="list-branches">
      <h2>{`List Branches`}</h2>
      <p>{`Use this account to list the available branches in the account for the logged in authenticated user.`}</p>
      <Message mdxType="Message">
        <p>{`Branches are how enableHR separates and organises Employees. Put simply, branches are set up in enableHR to mirror the organisation’s structure. There are two types of branches:`}</p>
        <ol>
    <li>“Legal entity” branches are used when the name of the branch needs to appear on documents generated by the system, such as a contract or a letter for instance.</li>
    <li>“Department / group” branches sit below a legal entity and are used to reflect the organisation’s structure. Typically, they represent locations or departments/teams. For instance, "Operations", "Sales", "New South Wales" or "Auckland" would operate under the parent legal entity / company.</li>
        </ol>
      </Message>
      <div className="m-5 text-center">
    <img src="/images/docs/accounts-and-branches/list-branches.png" alt="List Branches" />
      </div>
      <div className="markdown-section">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}></th>
              <th parentName="tr" {...{
                "align": null
              }}></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Method`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/development/ListBranches/"
                }}>{`GET: List Branches`}</a></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "#account-id"
                }}>{`Account ID`}</a>{` - Unique identifier of the Account`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Response`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><TableListBranches mdxType="TableListBranches" /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/accounts-and-branches/account-settings.png" alt="Account Settings" style={{
          "maxWidth": "60%"
        }} />
      </div>
    </div>
    <h2>{`Retrieve Branch Details`}</h2>
    <p>{`This action will return more details about a branch for the logged in authenticated user.`}</p>
    <Message mdxType="Message">
      <p>{`Branches are how enableHR separates and organises Employees. Put simply, branches are set up in enableHR to mirror the organisation’s structure. `}</p>
      <p>{`A branch can have an unlimited number of sub-branches. Sub-branches can be linked to one and only one parent branch.`}</p>
      <p>{`You can define specific branches for the applicant portal and the WHS portal.`}</p>
    </Message>
    <div className="m-5">
    <img src="/images/docs/accounts-and-branches/retrieve-branch-details.png" alt="Retrieve Branch Details" style={{
        "maxWidth": "80%"
      }} />
    </div>
    <div className="markdown-section">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Method`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/development/RetrieveBranch/"
              }}>{`GET: Retrieve Branch Details`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Parameters`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "#account-id"
              }}>{`Account ID`}</a>{` - Unique identifier of the Account `}<br /><a parentName="td" {...{
                "href": "#list-branches"
              }}>{`Branch ID`}</a>{` - Unique identifier of the Branch`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Response`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><TableRetrieveBranchDetails mdxType="TableRetrieveBranchDetails" /></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="m-5 text-center">
    <img src="/images/docs/accounts-and-branches/entity-details.png" alt="Entity Details" />
    </div>
    <div className="m-5 text-center">
    <img src="/images/docs/accounts-and-branches/account-details.png" alt="Account Details" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      